<template>
<v-app>
    <v-sheet height="100%" class="d-flex flex-column justify-center align-center px-4">
        <v-container fluid>
            <v-row align="center" justify="center" >
                <v-col
                    class="d-flex flex-column text-center align-center"
                    cols="12"
                    sm="12"
                >
                    <img 
                        class="ml-1"
    
                        width="200px"
                        src="/storage/app/media/Logotypes/LOGO-AR-VERTICAL-COULEUR.png"
                    >
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid >
            <v-row align="center" justify="center">
                <v-col
                    class="d-flex flex-column text-center "
                    cols="12"
                    sm="12"
                >
                    <div class="primary--text mb-4">
                        {{ $t('title.selectEtab')}}
                    </div>
                    <v-select
                    solo
                    :items="etabs"
                    label="Residence"
                    @change="changeEtab"
                    prepend-inner-icon="mdi-home"
                    item-text="name"
                    item-value="slug"
                    ></v-select>
                    <div class="d-flex  justify-center align-center">
                      <LangSwitcher />
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-sheet>
</v-app>
</template>
<style>
#app{
  width:100%;
}
.router-link-exact-active{
  text-decoration: none;
}
.v-card__title{
  font-size:1rem!important;
  word-break: break-word!important;
  line-height: 1.2rem!important;
}
.v-text-field.searchField{
  margin-top:0;
  padding-top:0;
}
</style>
<script>

import LangSwitcher from '@/components/LangSwitcher.vue'

  export default {
    components: {
      LangSwitcher
    },
    props: ['etab'],
    computed: {
      etabs() {
        return this.$store.getters.allEtabs
      }
    },
    data () {
      return {
        searchText: null,
        drawer: null,
        searching: false,
        Covid: true,
        icons: [
          'mdi-home',
          'mdi-email',
          'mdi-calendar',
          'mdi-delete',
        ],
        padless: true,
        variant: 'default',
      }
    },
    methods: {
      searchToggle() {
        this.searching = !this.searching;
        if ( this.searching ) {
          this.$nextTick(() => this.$refs.searchField.focus());
        }
      },
      changeEtab(e) {
          this.$router.push({ name: 'home', params:{etab: e} });
      }
    },
    mounted() {
      this.$store.dispatch('getEtabs')
      document.title = `Alpine Resorts - Room Directory `
    }
    
  }
</script>
